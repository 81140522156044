import React, { useEffect, useState } from "react";
import "./JournalsComponent.css";
import Sample from "../../img/sample.png";
import JournalsCard from "./JournalsCard/JournalsCardComponent";
import { db } from "../../firebase";

function JournalsComponent() {
  const [journals, setJournals] = useState([]);
  useEffect(() => {
    const fetchdata = async () => {
      db.collection("journals").onSnapshot(function (data) {
        setJournals(
          data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      });
    };
    fetchdata();
  }, []);

  let counter = 0;
  return (
    <React.Fragment>
      <section className="journal-header">
        <div className="imgContent"></div>
        <h4>Featured Journals</h4>
      </section>

      <div className="first-con">
        {journals.map((Journals) => {
          counter++;
          console.log(counter);
          return (
            <JournalsCard
              heading={Journals.heading}
              time={Journals.time}
              category={Journals.category}
              img={Sample}
              author={Journals.author}
              pdf={Journals.pdf}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default JournalsComponent;
