import React from "react";
import "./ImageComponent3.css";
import { FaLinkedin } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaGithubSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { CgMail } from "react-icons/cg";
import { FaEnvelope } from "react-icons/fa";
import { FaEnvelopeSquare } from "react-icons/fa";

const image = (props) => {
  const path = props.path;
  let img = null;
  if (path != null) {
    img = <img className="personImage" src={props.path} alt={props.name} />;
  }

  const contact = parseInt(props.contact);
  let text = null;
  let cta = null;
  if (contact > 0) {
    text = (
      <div>
        <br />
        <i className="fa fa-phone-alt"></i>{" "}
        <a href={"tel:+91" + props.contact}>+91-{props.contact}</a>
      </div>
    );

    cta = (
      <a href={"tel:+91" + props.contact} className="cta">
        <i className="fas fa-phone-alt"></i> Call Now
      </a>
    );
  }

  const post = props.post;
  let text2 = null;
  if (post != null) {
    text2 = (
      <div>
        <span className="personPost">{props.post}</span>
      </div>
    );
  }

  return (
    <div className="personCard">
      {img}
      <div className="personDetails">
        <h4>{props.name}</h4>
        <p>
          {text2}
          {text}
        </p>
      </div>
      <div className="personBranch">
        <p>
          {props.children.toUpperCase()}
          <br />
          {cta}
        </p>
        {props.social && (
          <p>
            Reach Out
            <br />
            <div className="socials">
              {props.email && (
                <a href={`mailto: ${props.email}`}>
                  <FaEnvelopeSquare />
                </a>
              )}
              {props.linkedin && (
                <a target="_blank" href={props.linkedin}>
                  <FaLinkedin />
                </a>
              )}

              {props.github && (
                <a target="_blank" href={props.github}>
                  <FaGithubSquare />
                </a>
              )}
              {props.twitter && (
                <a target="_blank" href={props.twitter}>
                  <FaSquareXTwitter />
                </a>
              )}
              {props.facebook && (
                <a target="_blank" href={props.facebook}>
                  <FaFacebookSquare />
                </a>
              )}

              {props.instagram && (
                <a target="_blank" href={props.instagram}>
                  <FaSquareInstagram />
                </a>
              )}
              {props.portfolio && (
                <a target="_blank" href={props.portfolio}>
                  <BsFillPersonVcardFill />
                </a>
              )}
            </div>
          </p>
        )}
      </div>
    </div>
  );
};

export default image;
