import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "bootstrap-social/bootstrap-social.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import './fonts/Flaticon.ttf'

ReactDOM.render(
  <React.StrictMode>
          {/* <StateProvider> */}
          <App />
    {/* </StateProvider> */}

  </React.StrictMode>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
