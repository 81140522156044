import React, { useEffect } from "react";
import Main from "./components/MainComponent";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import config from "react-reveal/globals";
import OneSignal from 'react-onesignal';

config({ ssrFadeout: true });

const App = () => {
  useEffect(() => {
    OneSignal.init({
      appId: "d5b99d17-c235-4e9e-bde2-2c37a9b5aef0"
    });
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Main />
      </div>
    </BrowserRouter>
  );
};

export default App;
