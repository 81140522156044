import React, { useRef } from "react";
import "./SignUpComponent.css";
import { useState } from "react";
import { Alert } from "reactstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

function SignUpComponent() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const fullnameRef = useRef();
  const semesterRef = useRef();
  const branchRef = useRef();
  const contactRef = useRef();
  const referralRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup, signupWithGoogle } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match!");
    }
    if (passwordRef.current.value.length <= 5) {
      return setError("Password should be of more than 5 characters!");
    }
    if (
      contactRef.current.value > 9999999999 ||
      contactRef.current.value < 1000000000
    ) {
      return setError("Invalid Contact No.");
    }

    // if (referralRef.current.value) {
    //   if (
    //     referralRef.current.value !== "DSCL24" &&
    //     referralRef.current.value !== "PMCS24" &&
    //     referralRef.current.value !== "PSCS24" &&
    //     referralRef.current.value !== "SSEC24" &&
    //     referralRef.current.value !== "GDCS24" &&
    //     referralRef.current.value !== "RACS24" &&
    //     referralRef.current.value !== "ARCL24" &&
    //     referralRef.current.value !== "PPIT24" &&
    //     referralRef.current.value !== "TLCS24" &&
    //     referralRef.current.value !== "AUCS24" &&
    //     referralRef.current.value !== "KJIT24" &&
    //     referralRef.current.value !== "DSCS24" &&
    //     referralRef.current.value !== "AFIT24" &&
    //     referralRef.current.value !== "MSEE24" &&
    //     referralRef.current.value !== "SMCS24" &&
    //     referralRef.current.value !== "ASCS24" &&
    //     referralRef.current.value !== "SSCS24" &&
    //     referralRef.current.value !== "HDEE24" &&
    //     referralRef.current.value !== "ZAIT24" &&
    //     referralRef.current.value !== "SPIT24" &&
    //     referralRef.current.value !== "ISIT24" &&
    //     referralRef.current.value !== "RSIT24" &&
    //     referralRef.current.value !== "VZCS24"
    //   ) {
    //     return setError("Invalid Referral Code");
    //   }
    // }

    try {
      setError("");
      setLoading(true);
      console.log("1 Hello");
      await signup(
        emailRef.current.value,
        passwordRef.current.value,
        fullnameRef.current.value,
        semesterRef.current.value,
        branchRef.current.value,
        contactRef.current.value
        // referralRef.current.value
      );
      console.log("2 Hello");
      history.push("/profile");
    } catch {
      setError("Failed to Create an Account");
    }
    setLoading(false);
  }

  async function handleSubmitWithGoogle(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await signupWithGoogle();
      history.push("/profile");
    } catch {
      setError("Failed to Login");
    }
    setLoading(false);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign Up | TechnoHub BITD</title>
      </Helmet>
      <div className="signUpCont">
        <div className="image"></div>
        <div className="form">
          <h2 className="title">Become a TechnoHub Member Now</h2>
          {error && (
            <Alert color="danger" style={{ marginBottom: "2em" }}>
              {error}
            </Alert>
          )}
          <form action="POST" onSubmit={handleSubmit}>
            <div className="inputGroup">
              <div className="input">
                <input
                  type="text"
                  name="name"
                  ref={fullnameRef}
                  id="name"
                  placeholder="Full Name*"
                  required
                />
                <label htmlFor="name">Full Name*</label>
              </div>
            </div>
            <div class="inputGroup twoInputs">
              <div className="input">
                <select name="branch" ref={branchRef} required>
                  <option value="">--Select Branch--</option>
                  <option value="Computer Science Engineering">
                    Computer Science Engineering
                  </option>
                  <option value="Electronics and Telecommunication">
                    Electronics and Telecommunication
                  </option>
                  <option value="Information Technology">
                    Information Technology
                  </option>
                  <option value="Electrical and Electronics">
                    Electrical and Electronics
                  </option>
                  <option value="Electrical Engineering">
                    Electrical Engineering
                  </option>
                  <option value="Civil Engineering">Civil Engineering</option>
                  <option value="Mechanical Engineering">
                    Mechanical Engineering
                  </option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="input">
                <select name="semester" ref={semesterRef} required>
                  <option value="">--Select Semester--</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                </select>
              </div>
            </div>
            <div className="inputGroup twoInputs">
              <div className="input">
                <input
                  ref={emailRef}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-mail ID*"
                  required
                />
                <label htmlFor="email">E-mail ID*</label>
              </div>
              <div className="input">
                <input
                  type="number"
                  name="contact"
                  ref={contactRef}
                  id="contact"
                  placeholder="Contact No.*"
                  required
                />
                <label htmlFor="contact">Contact No.*</label>
              </div>
            </div>
            <div className="inputGroup twoInputs">
              <div className="input">
                <input
                  type="password"
                  name="password"
                  ref={passwordRef}
                  id="password"
                  placeholder="Password*"
                  required
                />
                <label htmlFor="password">Password*</label>
              </div>
              <div className="input">
                <input
                  type="password"
                  name="cpassword"
                  ref={passwordConfirmRef}
                  id="cpassword"
                  placeholder="Confirm Password*"
                  required
                />
                <label htmlFor="cpassword">Confirm Password*</label>
              </div>
            </div>
            {/* <div className="inputGroup">
              <div className="input">
                <input
                  type="text"
                  name="rcode"
                  ref={referralRef}
                  id="rcode"
                  placeholder="Referral Code*"
                />
                <label htmlFor="rcode">Referral Code</label>
              </div>
            </div> */}
            <div className="link">
              Already a member? <Link to="/login">Log in</Link> now.
            </div>
            <div className="inputGroup twoInputs input-group">
              <button type="submit" disabled={loading}>
                Sign Up
              </button>
              <button
                type="button"
                className="google"
                onClick={handleSubmitWithGoogle}
              >
                <i className="fab fa-google"></i>&nbsp;&nbsp;Continue with
                Google
              </button>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignUpComponent;
