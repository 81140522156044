import React, { Component } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../BreadcrumbComponent/BreadcrumbComponent";
import Image from "./ImageComponent/ImageComponent";
import Image2 from "./ImageComponent2/ImageComponent2";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import HeaderTitle from "../HeaderComponents/HeaderTitle";
import { Helmet } from "react-helmet";
import "./AboutUsStyles.css";
import { Zoom, Fade } from "react-reveal";

class About extends Component {
  state = {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
    responsive2: {
      0: {
        items: 1,
        dots: false,
        dotsEach: false,
      },
      600: {
        items: 1,
        dots: false,
        dotsEach: false,
      },
      768: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  render() {
    return (
      <React.Fragment>
        <HeaderTitle heading="ABOUT THE CLUB" image="about-us.jpg" />
        <Helmet>
          <title>About The Club | TechnoHub BITD</title>
        </Helmet>
        <div className="aboutus-container">
          <Breadcrumb>
            <BreadcrumbItem icon="fas fa-home" title="Home" path="/" />
            <BreadcrumbItem
              icon="fas fa-info-circle"
              title="About Us"
              status="active"
            />
          </Breadcrumb>
          <div className="container">
            <div className="row row-content align-items-center justify-content-center">
              <Fade>
                <div className="col-12 col-md-4">
                  <img
                    src="./assets/images/logo.png"
                    height="150"
                    width="260"
                    alt="Technohub"
                  />
                </div>
              </Fade>
              <Zoom>
                <div className="col-12 col-md-8">
                  <h2>TechnoHub</h2>
                  <br />
                  <p className="aboutText">
                    The club provides an ambiance for students to prepare and to
                    showcase their skills at technical events and
                    entrepreneurship held all across India and Asia in various
                    colleges with the aim to add more feathers in the cap. The
                    ultimate goal of the TechnoHub club is to educate more
                    students and make robotics and entrepreneurship an everyday
                    phenomenon. The focus of the TechnoHub club primarily is to
                    help the students gain practical as well as theoretical
                    technical knowledge. The Journey started with a mere some 4
                    members and today it is a colossal family of 250+ members.
                  </p>
                </div>
              </Zoom>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Training & Placement Officer</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel className="owl-theme" items={1}>
                <Fade up>
                  <Image
                    path="./assets/images/core22/manisha.jpg"
                    name="Dr. Manisha Sharma"
                    post="Head"
                  >
                    TPO Head
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Teacher Incharge</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel className="owl-theme" items={1}>
                <Fade up>
                  <Image
                    path="./assets/images/core22/Shubham Dashore.jpg"
                    name="Shubham Dashore"
                    post="Incharge"
                  >
                    Teacher Incharge
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>President</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel className="owl-theme" items={1}>
                <Fade up>
                  <Image
                    path="./assets/images/core22/Kritika.jpg"
                    name="Kritika Upadhyay"
                    contact="9109079518"
                  >
                    Civil
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Vice President</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel className="owl-theme" items={1}>
                <Fade up>
                  <Image
                    path="./assets/images/core22/Anubhav.jpg"
                    name="Anubhav Bhatt"
                    contact="8234993336"
                  >
                    Mechanical
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>
          {/* General Manager Start */}
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>General Managers</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                responsive={this.state.responsive2}
              >
                <Fade up>
                  <Image
                    path="./assets/images/core22/Aaryan.jpg"
                    name="Aaryan Khandelwal"
                    contact="8103445828"
                  >
                    Electronics and Telecommunication
                  </Image>

                  <Image
                    path="./assets/images/core22/Shalini.jpg"
                    name="Shalini Shrivas"
                    contact="7067303170"
                  >
                    Electronics and Telecommunication
                  </Image>
                  <Image
                    path="./assets/images/core22/Surbhi.jpg"
                    name="Surbhi Dewangan"
                    contact="8871198437"
                  >
                    Electronics and Telecommunication
                  </Image>

                  <Image
                    path="./assets/images/core22/Narayan.jpg"
                    name="Baviseti Narayan"
                    contact="9406108766"
                  >
                    Electronics and Telecommunication
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>
          {/* General Manager End */}

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Program Managers</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                responsive={this.state.responsive}
              >
                <Fade up>
                  <Image
                    path="./assets/images/core22/Tanvi.jpg"
                    name="Tanvi Sanghavi"
                    post="Program Manager"
                    contact="9111007754"
                  >
                    Electrical and Electronics
                  </Image>

                  <Image
                    path="./assets/images/core22/Yukti.jpg"
                    name="Yukti Jhawar"
                    post="Associate Program Manager"
                    contact="9179197697"
                  >
                    Electronics and Telecommunication
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>

          {/* Project Managers start */}
          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Project Managers</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                responsive={this.state.responsive2}
              >
                <Fade up>
                  <Image
                    path="./assets/images/core22/Manya.jpg"
                    name="Manya Dewangan"
                    post="Operations Manager"
                    contact="7999511156"
                  >
                    Electronics and Telecommunication
                  </Image>

                  <Image
                    path="./assets/images/core22/Manasdiwan.jpg"
                    name="Manas Diwan"
                    post="Public Relations Manager"
                    contact="7773059502"
                  >
                    Electronics and Telecommunication
                  </Image>

                  <Image
                    path="./assets/images/core22/Pratikarsh.jpg"
                    name="Pratikarsh Singh"
                    post="Projects Manager"
                    contact="8817866643"
                  >
                    Mechenical
                  </Image>

                  <Image
                    path="./assets/images/core22/Ankit.png"
                    name="Ankit Kumar"
                    post="Aptitude Manager"
                    contact="9430355225"
                  >
                    Computer Science
                  </Image>
                  <Image
                    path="./assets/images/core22/Ritesh.jpg"
                    name="Ritesh Thakur"
                    post="Technical Manager"
                    contact="8349158303"
                  >
                    Computer Science
                  </Image>

                  <Image
                    path="./assets/images/core22/Namshit.jpg"
                    name="Namshit Manikpuri"
                    post="Co-Social Media Manager"
                    contact="9406448158"
                  >
                    Computer Science
                  </Image>
                  <Image
                    path="./assets/images/core22/Manasbanjare.jpg"
                    name="Manas Banjare"
                    post="Co-Social Media Manager"
                    contact="8827134742"
                  >
                    Computer Science
                  </Image>

                  <Image
                    path="./assets/images/core22/Nihal.jpg"
                    name="Nihal Singh"
                    post="Finance Manager"
                    contact="8817366902"
                  >
                    Electronics and Telecommunication
                  </Image>

                  <Image
                    path="./assets/images/core22/Smriti.jpg"
                    name="Smriti Pandey"
                    post="Workshops Manager"
                    contact="7224970876"
                  >
                    Electronics and Telecommunication
                  </Image>
                  <Image
                    path="./assets/images/core22/Shruti.jpg"
                    name="Shruti Dixit"
                    post="Content Lead"
                    contact="9752874353"
                  >
                    Electronics and Telecommunication
                  </Image>
                  <Image
                    path="./assets/images/core22/Jayash.jpg"
                    name="Jayash"
                    post="Graphics and Video Co-lead"
                    contact="7389120420"
                  >
                    Information Technology
                  </Image>
                  <Image
                    path="./assets/images/core22/Akshat.jpg"
                    name="Akshat Pandey"
                    post="Graphics and Video Co-lead"
                    contact="8770675685"
                  >
                    Computer Science
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>
          {/* Project Managers End */}

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Coordinators</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                responsive={this.state.responsive2}
              >
                <Fade up>
                  <Image
                    path="./assets/images/core22/Nikhil.jpg"
                    name="Nikhil Singh"
                    contact="9644526993"
                  >
                    Electrical and Electronics
                  </Image>
                  <Image
                    path="./assets/images/core22/Shreya.jpg"
                    name="Shreya Sahu"
                    contact="7067477909"
                  >
                    Electronics and Telecommunication
                  </Image>
                  <Image
                    path="./assets/images/core22/Shekhar.jpg"
                    name="Shekhar Kushwaha"
                    contact="6266689662"
                  >
                    Electronics and Telecommunication
                  </Image>
                </Fade>
              </OwlCarousel>
            </div>
          </div>

          {/* Secretaries start */}
          {/* <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Secretaries</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <OwlCarousel
                className="owl-theme"
                autoplay
                autoplayTimeout={2000}
                autoplayHoverPause
                responsive={this.state.responsive2}
              >
                <Fade up></Fade>
              </OwlCarousel>
            </div>
          </div> */}
          {/* Secretaries End */}

          <div className="container">
            <div className="row">
              <div className="col-xl-4">
                <div className="title">
                  <h2>Our Vision</h2>
                  <br />
                </div>
              </div>
            </div>
            <div className="row text-justify ml-1">
              <p>
                ‘IMAGINE INVENT INSPIRE ‘TechnoHub, revolves and works under
                this catchphrase. Think of society with the technical
                perspective and lead the society technically (technical
                startups) to spread awareness of technology. The most impressive
                thing about TechnoHub is that it believes in team work. Each and
                every session and workshop is consummate because of each and
                every individual’s contribution. It toils on the principle
                ‘IMAGINE INVENT INSPIRE ‘ where each and every individual is
                given equal opportunity . TechnoHub stands out from the rest of
                the clubs because of its assiduous members and constant guidance
                and support from the faculty members. It is a matter of
                amour-propre and repletion that TechnoHub has its own website
                wherein students get to know about the upcoming events as well
                as they get to interact with the seniors to collab with them
                having same kind of interest. Technical part of TechnoHub has
                60+ members working in the technical ground and take up projects
                and showcase their talent. Also compete with other splendid
                talent.
              </p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
