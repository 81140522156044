import React from "react";
import { Breadcrumb, BreadcrumbItem } from "../BreadcrumbComponent/BreadcrumbComponent";
import HeadingTitle from "../HeaderComponents/HeaderTitle";
import "./ProjectsComponent.css";
import SingleProject from "./SingleProjectComponent/SingleProject";
import { Helmet } from "react-helmet";
import { Zoom } from 'react-reveal';

const Projects = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Projects | TechnoHub BITD</title>
        <meta
          name="description"
          content="TechnoHub project section inspires you to imagine and invent. It gives you an idea of what the club members are working on and to start your own project by collaborating with the likeminded people."
        />
      </Helmet>
      <HeadingTitle heading="OUR PROJECTS" image="projects.jpg" />
      <div className="gallery-container">
        <Breadcrumb>
          <BreadcrumbItem icon="fas fa-home" title="Home" path="/" />
          <BreadcrumbItem
            icon="fas fa-microchip"
            title="Our Projects"
            status="active"
          />
        </Breadcrumb>
        <div className="container">
          <div className="row">
            <div className="title">
              <h2>Our Projects</h2>
              <br />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="cardCont">
                <Zoom>
                  <SingleProject
                    img="./assets/images/projects/gas-detector.jpg"
                    title="Gas Leakage Detector and Controller"
                  >
                    Under AICTE IDEA Lab this project was built which provides
                    the information of gas leakage,MQ-2 gas sensor is used to
                    detect the LPG gas leakage,when it happens buzzer
                    immediately turns on and gas sensor also triggers the servo
                    motor to turn off the knob of regulator until gas leakage
                    stops.
                  </SingleProject>
                  <SingleProject
                    img="./assets/images/projects/traffic.jpg"
                    title="Traffic Management System"
                  >
                    This System was proposed to resolve the problem faced by Emergency
                    Vehicles during any kind of emergency i.e. Ambulance, Fire
                    Brigade, Police Vans, mainly at the traffic signals.
                    Sometimes due to traffic congestion emergency vehicles get
                    stuck in the traffic.
                  </SingleProject>
                  <SingleProject
                    img="./assets/images/projects/accident-avoiding.jpg"
                    title="Accident Avoiding System"
                  >
                    This is an Aurdino-based Project which helps citizens to
                    avoid accidents and also when an accident occurs it can help
                    an individual to get treatment for his/her injuries anywhere
                    on earth.
                  </SingleProject>
                  <SingleProject
                    img="./assets/images/projects/smart-stick.png"
                    title="Smart Stick for Blind"
                  >
                    Made under AICTE IDEA Lab  Summer Internship, an innovative stick designed for visually disabled people
                    for improved navigation, that allows visually challenged
                    people to navigate with ease using advanced technology.
                  </SingleProject>
                  <SingleProject
                    img="./assets/images/projects/drone.jpg"
                    title="Quadcopter"
                  >
                    Drone surveillance is the use of unmanned aeriel vehichle to
                    capture still images and video to gather information about
                    specific target which might be idividuals, groups or
                    environments.
                  </SingleProject>

                  <SingleProject
                    img="./assets/images/projects/eyantra.jpg"
                    title="e-yantra - Nutty Squirrel"
                  >
                    A squirrel builds itself an elevator mechanism to ease its
                    load-carrying capacity.
                  </SingleProject>

                  <SingleProject
                    img="./assets/images/projects/firebird.jpg"
                    title="Fire Bird V"
                  >
                    This bot detects colours and picks a particular coloured box
                    and place it to the destination.
                  </SingleProject>

                  <SingleProject
                    img="./assets/images/projects/weight-detector.jpeg"
                    title="Visual Weight Detector"
                  >
                    The aim of this project is to present a visual body weight
                    estimation, which is suitable for medical applications.
                  </SingleProject>

                  <SingleProject
                    img="./assets/images/projects/thagda-dam.jpg"
                    title="Simulation of Thagda Dam"
                  >
                    Akshay Sharma’s 3D modeling of Thagda Dam park and
                    recreation got approved by the CMO of Chhattisgarh for use
                    in the upcoming project.
                  </SingleProject>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Projects;

